import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';

const AshGuidesInherit: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Inheritance Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_inherit.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Inheritance Guide</h1>
          <h2>All you need to know about Inheritance in Ash Echoes.</h2>
          <p>
            Last updated: <strong>19/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction - What are Inheritances?" />
        <p>
          Inheritances are, simply put, extra <strong>regular</strong> skills
          that you can add to an Engraving to massively increase the potency of
          your builds. Up to 4 skills can be inherited per Engraving and all are
          guaranteed to be obtained at their inherited level simply by
          completing the Nexus run, so long as you spend the skill points to
          secure them at the end like any other skill.
        </p>
        <StaticImage
          src="../../../images/ash/generic/inherit_1.webp"
          alt="Guides"
        />
        <p>Picking your 4 Inherits is done before you start a Nexus run</p>
        <SectionHeader title="Leveling up Inherited Skills" />
        <p>
          Probably the most confusing part about the Inheritance system is the
          way to acquire specific skills and how to level them up.
        </p>
        <p>
          There are multiple ways to go about securing skills and leveling them
          up and it all depends on the state of your account, which we’ll go
          through in a bit.
        </p>
        <p>
          First, let’s talk about the way to get an inheritance to lvl3. The
          level of the inherited skill depends on the number of{' '}
          <strong>
            regular skills (only those will be counted in the following
            explanations)
          </strong>{' '}
          you pick once your Nexus run is completed:
        </p>

        <ul>
          <li>
            If you select <strong>less than 7</strong> skills, the inherited
            skill will always be <strong>lvl1</strong> regardless of its level
            at the time you completed the run;
          </li>
          <li>
            If you select <strong>between 7 and 13</strong> skills then the
            inherited skill will{' '}
            <strong>
              at most be lvl2, but could also be lvl1 if you couldn’t bring it
              up to lvl2 during the run
            </strong>
            ;
          </li>
          <li>
            <strong>From 14 selected skills</strong> the inheritance can reach
            lvl3.
          </li>
        </ul>
        <p>
          As a rule of thumb, <strong>always pick the minimum</strong> amount of
          skill required for the skill lvl you’re aiming for, so respectively{' '}
          <strong>1, 7 and 14</strong>, because there is currently no benefit in
          choosing more skills than the minimum.
        </p>
        <SectionHeader title="Possible Strategies" />
        <p>
          There are a couple of possible strats to efficiently grind your
          inheritances, and{' '}
          <strong>all can be done by spamming Blood Ruvia - Lab on auto</strong>{' '}
          as it’s the easiest and least gimmicky Nexus Path (you can even avoid
          the final battle to be more time efficient):
        </p>
        <ul>
          <li>
            Let’s first get the generic skills out of the way, as they are by
            far the easiest to inherit at lvl3. Simply stack as many Memory
            Traces featuring the skill and getting both lvl3 and 14 regular
            skills should happen every run, even on full auto. After that it’s
            all about retrying the runs or using Basis Reconstruction (more on
            that later) until you finally get the skill you’re looking for.
          </li>
          <li>
            For the uncommon regular skills, like Devotion for example, there’s
            a couple of similar ways to get the job done:
          </li>
          <ul>
            <li>
              If you have multiple MTs featuring the skill, great, you’ll have
              an easier time and can directly skip lvl2 and instead do a lvl1
              straight to lvl3. You’re going to secure the lvl1 inherit by
              selecting the skill alone, then if you can consistently get lvl2
              without the inherit you can now get it to lvl3 in a run thanks to
              the inherit;
            </li>
            <li>
              If you only have one source for the skill or even lack all of
              them, you’ll have to settle for a longer grind. Secure lvl1, then
              add the inherit and redo the run in the hopes of upgrading the
              inherit to lvl2. Once that is done you can finally repeat a few
              runs with the lvl2 inherit until the lvl3 drops.
            </li>
          </ul>
        </ul>
        <p>
          Other than that, another very useful advice would be to always run
          multiple “work in progress” inherited skills, preferably all 4, during
          your farming runs, as it simply multiplies the odds of getting a
          useful inherit out of the run.
        </p>
        <StaticImage
          src="../../../images/ash/generic/inherit_2.webp"
          alt="Guides"
        />
        <p>
          The 6 skills selected at the end of the Nexus run, locked to the
          Engraving. No other skill than them can be obtained by rerolling
        </p>
        <SectionHeader title="Basis Reconstruction" />
        <StaticImage
          src="../../../images/ash/generic/inherit_3.webp"
          alt="Guides"
        />
        <p>
          The Basis Reconstruction screen, with its 6 possible outcomes on the
          bottom left
        </p>
        <p>
          The reroll mechanic introduced alongside inheritance allows you to
          reroll the inherited skill of an engraving at the cost of a currency
          obtained by grinding your Weekly Nexus runs. You can find the Basis
          Reconstruction tab in the Tactical Engraving section at the bottom of
          the lobby.
        </p>
        <p>
          When obtaining an inherit after a Nexus run, the system picks a random
          skill among the 6 that you’ve upgraded the most. These 6 skills are
          locked to the Engraving and you cannot reroll another skill from this
          Engraving.
        </p>
        <p>
          As a side-note, the inherited skills come with extra Engraving Stats
          attached to them and rerolling can also change said stats. It is
          however not recommended to give any importance to those stats as the
          farming process to obtain the desired skills to lvl3 is already long
          and the stats themselves offer very little boost to your builds.
        </p>
        <SectionHeader title="The list of Inherited Skills" />
        <p>
          At some point, your list of Tactical Engravings is going to look like
          a mess, with dozens or even hundreds of Engravings. It’s important to
          regularly trim it down and arrange it in a way that works for you,
          otherwise it will get frustrating.
        </p>
        <p>
          While you can easily sort the inherits by Acquisition date when you’re
          in the progress of leveling up your inherits, the inherited skill
          doesn’t immediately show so it’s not a viable long term filter.
        </p>
        <p>
          To help with sorting it all out, the game offers us Tags that can be
          added to your Engravings in order to very easily sort them out. It
          might not be the most engaging thing to do, but classifying your
          Engravings will really help you save time and remove any kind of
          frustration associated with not finding that one Engraving in the
          Haystack, so to speak.
        </p>
        <p>
          You’ll be able to easily divide your Engravings into High Rating,
          actual meant-to-be-played Engravings, Joint Training fillers, Inherits
          of diverse types (you could sort them into Universal, Square,
          Triangle, Diamond for example), etc.
        </p>
        <p>
          Lastly, don’t forget that you can destroy Engravings you do not need
          as long as they’re not currently in use and they’re not the highest
          rating Engravings of that Leader, it even gives some extra MT mats to
          improve your account! For that, use the remove option on the top right
          in the Tactical Engraving screen.
        </p>
        <StaticImage
          src="../../../images/ash/generic/inherit_4.webp"
          alt="Guides"
        />
        <p>
          The messy business of farming dozens of Nexus runs, and how to order
          them
        </p>
        <SectionHeader title="Skills to look out for" />
        <p>
          The following list of skills is a mix of extremely useful, generic
          skills that you will be able to play in very diverse team comps, as
          well as some of the most powerful but more situational skills that can
          single-handedly take a build from good to amazing.
        </p>
        <p>
          Disclaimer: The skills mostly come from seasoned CN server veterans
          and theory-crafters, and not all of them were thoroughly tested by our
          team.
        </p>
        <p>
          If you want to pick skills that are not listed here but don’t know
          where to find them, go to the Memory Trace screen, then on the top
          click on the Skill List icon. You can filter in many ways to make
          navigation easier.
        </p>

        <StaticImage
          src="../../../images/ash/generic/inherit_5.webp"
          alt="Guides"
        />
        <h5>Universal</h5>
        <ul>
          <li>Devotion : Warm Winter / Your Yummiest Friend</li>
          <ul>
            <li>
              By far the most versatile and one of the most powerful skills to
              inherit, Devotion is a staple in just about every possible build.
              You’d need to look into extremely obscure setups to find one that
              could do without it, that’s how good this one is.
            </li>
          </ul>
          <li>
            Danger Alert : In-Between Time / Remaining Friends / Blending In
          </li>
          <ul>
            <li>
              If you play Thea, Danger Alert is almost always a safe pick. It
              might not be the best option in every situation, but its ease of
              use and wide range of applications makes it more than worth
              grinding for.
            </li>
          </ul>
          <li>
            My Domain : Wet Path / Born From Ashes / Warm Winter / One More Step
            / Divination
          </li>
          <ul>
            <li>
              Although the skill can be used by most Echomancers belonging to
              Elements with Zones, it is most often used for Square Type
              Echomancers who by nature tend to stand inside of their Zones.
              Still, you can consider playing this Skill with other Types if you
              do not have better alternatives.
            </li>
          </ul>
          <li>Peak Duel: Boss : Wet Path / “Cat vs. Dog”</li>
          <ul>
            <li>
              While not the highest multiplier out there, Peak duel is an
              extremely easy skill to trigger and works in ALL single target
              Boss encounters. If you’re unwilling to go through the grind of
              dozens of Inherits and just want a good (but not optimal) option
              for almost every Boss fight out there, pick it up.
            </li>
          </ul>
        </ul>
        <h5>Square</h5>
        <ul>
          <li>
            Class Link - Square : Testing Limits / Where The Star Fell / The
            Unexpected
          </li>
          <li>
            Strength Works Miracles : Company / Blows? Bonds? / Hiding In The
            City
          </li>
          <li>Recycling : Behind The Curtains / Strongest Summoner</li>
          <li>Distance Control : RPG / Blood Ruvia - Secret / Nightmare</li>
          <li>
            Elemental Transformation : Festive Morning / Into A Paper Crane
          </li>
          <li>Starry Trail - Square : Wet Path</li>
          <li>Seed Charge - Square :</li>
          <ul>
            <li>
              Alpha = One More Step / Festive Morning / “Cat vs. Dog” / The
              Unexpected / Blows? Bonds? / Dream Of Cherry Blossoms / Where The
              Sun Is / Mars;
            </li>
            <li>
              Beta = Behind The Curtains / RPG / Testing Limits / Traces In The
              Museum / Warm Winter / Night In The City / The Weight Of Life /
              Begonia
            </li>
          </ul>
        </ul>
        <h5>Triangle</h5>
        <ul>
          <li>Class Link - Triangle : Going Off-Course / Secret Melody</li>
          <li>Strike The Heart : Sirius / Light Of Knowledge / Filing</li>
          <li>
            Getting Into The Swing : My Vision Is Clear / Curiosity Of A
            Hailinian
          </li>
          <li>
            Cheers To Friendship : Growth / Name Of The Line / the Sun Never
            Sets
          </li>
          <li>
            Storm Fury - Triangle : Beta = Going Off-Course / Growth / Get Well
            Soon
          </li>
          <li>
            Alpha = Shadows Of The Alley / Hidden Blade / Name Of The Line /
            Lightchaser / Caroline
          </li>
          <li>Seed Charge - Triangle :</li>
          <ul>
            <li>
              Alpha = Name Of The Line / Light Of Knowledge / Get Well Soon /
              Filing / Furay & Furney;
            </li>
            <li>
              Beta = Karma / Deadline / the Sun Never Sets / Smoke Of Chaos
            </li>
          </ul>
        </ul>
        <h5>Diamond</h5>
        <ul>
          <li>Class Link - Diamond : Into The Fantasy / Wish In The Rain</li>
          <li>
            Getting The Groove : Traces In The Museum / Lucky Find / Bewitching
            Night
          </li>
          <li>Energy Release : Strongest Summoner / Secret Melody</li>
          <li>
            Seed Overload : Into The Fantasy / Behind The Curtains / Double
            Attraction / Beneath The Deception / Warm Winter / Lucky Find / The
            Weight Of Life
          </li>
          <li>Can’t Hurt To Be Resourceful : Codename “Diversity” / Reunion</li>
          <li>Seed Charge - Diamond :</li>
          <ul>
            <li>
              Alpha = Born From Ashes / Nightmare / In-Between Time / Lawful
              Cage / Sweet Thoughts / Old Alley / The Weight Of Life / Bellia;
            </li>
            <li>
              Beta = Strongest Summoner / Into The Fantasy / Warm Winter /
              Memories Of The Past / Wish in The Rain / Old Alley / Aceo
            </li>
          </ul>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesInherit;

export const Head: React.FC = () => (
  <Seo
    title="Inheritance Guide | Ash Echoes | Prydwen Institute"
    description="All you need to know about Inheritance in Ash Echoes."
    game="ash"
  />
);
